/*=================================================================*/
/*                      ARROYO                              
/*=================================================================*/
@media (max-width: 750px) {
  #arroyo {
    flex-direction: column;
  }
}

#arroyo {
  display: flex;
  align-items: center;
  gap: 20px;
}



#arroyo .center {
  text-align: center;
  margin-bottom: 50px;
}

.arroyo .snippet {
  margin: 50px 0 50px 0;
}

#arroyo p {
  color: white;
  font-size: 1rem;
}

.arroyo-gradient {
  background: rgb(74,99,231);
  background: linear-gradient(90deg, rgba(74,99,231,1) 0%, rgba(24,115,152,1) 18%, rgba(8,6,1,1) 100%);  
}

.arroyo-media {
  min-width: 250px;
}

.arroyo-media img {
  border-radius: 20px;
}



